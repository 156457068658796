/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout-has-sider {
  align-items: center;
}
.ant-layout-sider {
  height: 100vh;
}
.ant-layout-sider ul.ant-menu-root {
  max-height: calc(100vh - 125px);
  overflow: auto;
  padding-bottom: 70px;
}
.ant-layout-sider .menu-icon {
  margin-right: 10px;
}
.ant-layout-sider .menu-icon .anticon {
  margin-right: 0;
}
.sidebar-logo {
  display: block;
  padding: 20px 25px 10px;
}
.sidebar-logo svg {
  max-width: 100%;
}
.ant-layout-sider-collapsed .sidebar-logo .ant-typography {
  display: none;
}
.ant-layout-sider-collapsed .ant-menu .fa-icon {
  display: block;
}
.main {
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
}
.main .ant-layout-content {
  margin: 0 25px;
  padding-bottom: 50px;
  flex: 1 0 auto;
}
.navbar {
  background-color: #fff;
  margin-bottom: 2px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.navbar .navbar-logo {
  display: block;
  width: 150px;
  height: 45px;
}
.navbar .navbar-logo svg {
  max-height: 100%;
  max-width: 100%;
}
.navbar .ant-dropdown-trigger {
  cursor: pointer;
  padding: 12px 0;
}
.navbar .ant-dropdown-trigger span {
  margin-right: 7px;
}
.root-layout:not(.ant-layout-has-sider) .navbar {
  height: 80px;
}
.navbar-overlay {
  min-width: 200px !important;
  top: 60px !important;
}
.navbar-overlay .ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.title-bar,
.ant-drawer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-bar .title,
.ant-drawer-title .title {
  margin: 0;
}
.title-bar {
  margin-bottom: 25px;
  background-color: #fff;
  padding: 10px 25px;
}
.ant-drawer-header .ant-typography {
  margin: 0;
}
.ant-drawer-header .header-left {
  display: flex;
  align-items: center;
}
.ant-drawer-header .header-left .ant-tag {
  margin: 2px 0 0 15px;
  border-radius: 15px;
}
.ant-drawer-body {
  overflow-x: hidden;
}
.ant-drawer-body .ant-form {
  padding-bottom: 76px;
}
.ant-drawer-body .form-actions {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-left: -24px;
  padding: 15px 24px;
  border-top: 1px solid #d9d9d9;
  z-index: 2;
}
