/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.logo-link[data-v-51933160] {
  text-align: center;
  margin: 50px 0;
}
.login-card[data-v-51933160] {
  text-align: center;
}
.login-card[data-v-51933160] .ant-card-body {
  padding-top: 40px;
  padding-bottom: 40px;
}
.login-card[data-v-51933160] .ant-card-body iframe {
  display: inline-block !important;
}
